import { reactive } from 'vue'
import mixins from '@/mixins'
import { logout } from '@/api/user/user-api'

interface GlobalError {
  title: string;
  detail: string;
  showGlobalMessage: boolean;
}

export class ErrorHandler {
  globalError: GlobalError

  constructor () {
    this.globalError = reactive({
      title: 'Error',
      detail: '',
      showGlobalMessage: true
    })
  }

  loadError (error: any) {
    if (error.status === 401) {
      logout()
      error.title = 'Session Expired'
      error.detail = 'We logged you out. Login again to continue using MyCMCM'
      error.showGlobalMessage = false
    } else if (error.status) {
      error.showGlobalMessage = true
    }
    console.log(error.detail)
    this.showGlobalError(error)
  }

  showGlobalError (error: GlobalError) {
    this.globalError.title = error.title
    this.globalError.detail = error.detail
    this.globalError.showGlobalMessage = error.showGlobalMessage
    mixins.methods.openModal('error')
  }
}
