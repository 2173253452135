<template>
  <a
      v-if="href !== undefined"
      :class="buttonClass"
      :href="href"
  >
    <Icon :notifications="notifications" class="icon-button__icon-component" :icon="iconName" /><slot />
  </a>
  <router-link
      v-else-if="to !== undefined"
      :class="buttonClass"
      :to="to"
  >
    <Icon :notifications="notifications" class="icon-button__icon-component" :icon="iconName" /><slot />
  </router-link>
  <button
      v-else
      :class="buttonClass"
      type="button"
  >
    <Icon :notifications="notifications" class="icon-button__icon-component" :icon="iconName" /><slot />
  </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  background?: string
  icon?: string
  small?: string
  medium?: string
  lowercase?: string
  href?: string
  to?: string
  round?: string
  notifications?: number
}

@Options({
  name: 'Icon-button',
  components: {
    Icon
  }
})

/**
 *
 * Button with icon on the left side
 *
 * @param {string} icon
 * @param {string=} background - Default: blue-dark
 * @param {string=} small
 * @param {string=} medium
 * @param {string=} lowercase
 * @param {string=} href
 * @param {string=} to
 * @param {string=} round - Used without text
 * @param {number=} notifications
 *
 */
export default class IconButton extends Vue.with(Props) {
  get iconName () {
    return this.icon
  }

  get buttonClass () {
    const classes = {
      button: true,
      lowercase: this.lowercase === '',
      small: this.small === '',
      medium: this.medium === '',
      round: this.round === ''
    }
    classes[`background--${this.background}`] = true
    return classes
  }
}
</script>

<style lang="scss" scoped>
// Transparent button blue text
.button {
  @include brandon-grotesque-bold;
  @include focus;
  appearance: none;
  background-color: $blue-dark;
  border: none;
  border-radius: 30px;
  color: $white;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  padding: 9px 17px 9px 10px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.icon-button__icon-component {
  height: 24px;
  width: 24px;
  margin-right: 6px;
  transform: translateY(-1px);
}

// Uppercase button
.button.lowercase {
  text-transform: unset;
}

// Small button
.button.small {
  @include brandon-grotesque;
  font-size: 14px;
  height: 25px;
  padding: 2px 9px 3px 5px;

  .icon-button__icon-component {
    height: 16px;
    transform: translateY(0);
    width: 16px;
  }
}

// Medium button
.button.medium {
  @include brandon-grotesque-bold;
  height: 32px;
  padding: 5px 17px 5px 10px;
}

// Transparent button
.button.background--transparent {
  background: transparent;
  color: $blue-dark;
  padding-left: 0;
  padding-top: 0;
}

.button.round {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0;
  width: 36px;

  .icon-button__icon-component {
    margin-right: 0;
  }
}
</style>
