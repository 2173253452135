export class Icons {
  /**
   * Define global icon components to be used in Icon.vue
   *
   */
  defineGlobalIcons (app: any) { // FIXME: replace "any" with the right type
    const requireComponent = require.context(
      './icons',
      false,
      /.vue$/
    )
    requireComponent.keys().forEach((fileName) => {
      // Get the component config
      const componentConfig = requireComponent(fileName)
      // Get the PascalCase version of the component name
      const componentName = fileName
        // Remove the "./" from the beginning
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('')
      // Globally register the component
      app.component(componentName, componentConfig.default || componentConfig)
    })
  }
}
