import { getCurrentPackage, getPackageRequest, getPackages, getRecommendedPackage } from '@/api/packages/packages-api'
import { reactive } from 'vue'

export interface Package {
  'id': string;
  'name': string;
  'benefits': string;
  'description': string;
  'type': string;
  'feeMonthly': number;
  'feeAnnually': number;
  'icon': string;
  'imageUrl': string;
  'detailsUrl': string;
  'packageOptions': Array<PackageOptions>;
}

export interface PackageOptions {
  'id': string;
  'name': string;
  'fee': number;
}

export interface RecommendedPackage {
  'title': string;
  'description': string;
  'notice': string;
  'packageDetails': Package;
  'detailsUrl': string;
}

interface TransferDetails {
  'iban': string;
  'bic': string;
  'transferId': string;
  'amount': number;
}

export interface PackageRequest {
  'id': string;
  'newPackage': Package;
  'status': string;
  'submittedAt': string;
  'paidAt': string;
  'notice': string;
  'transferDetails': TransferDetails;
}

export class PackageService {
  packages: Array<Package>
  currentPackage: Package
  recommendedPackage: RecommendedPackage
  packageRequest: PackageRequest

  constructor () {
    this.packages = reactive({}) as Array<Package>
    this.currentPackage = reactive({}) as Package
    this.recommendedPackage = reactive({}) as RecommendedPackage
    this.packageRequest = reactive({}) as PackageRequest
  }

  loadPackages (forceReload = false) {
    if (this.packages.length && !forceReload) {
      return Promise.resolve()
    }

    return getPackages()
      .then((response) => {
        this.packages = response.data
      })
  }

  loadCurrentPackage (forceReload = false) {
    if (this.currentPackage.name && !forceReload) {
      return Promise.resolve()
    }

    return getCurrentPackage()
      .then((response) => {
        this.currentPackage = response.data
      })
  }

  loadRecommendedPackage (forceReload = false) {
    if (this.recommendedPackage.title && !forceReload) {
      return Promise.resolve()
    }

    return getRecommendedPackage()
      .then((response) => {
        this.recommendedPackage = response.data
      })
  }

  loadPackageRequest (forceReload = false) {
    if (this.packageRequest.id && !forceReload) {
      return Promise.resolve()
    }

    return getPackageRequest()
      .then((response) => {
        this.packageRequest = response.data
      })
  }

  loadPackageService () {
    return Promise.all([
      this.loadPackages(),
      this.loadCurrentPackage(),
      this.loadPackageRequest()
    ])
  }
}
