<template>
  <div class="icon_container">
    <span v-if="notifications > '1'" class="icon__notification">{{ notifications }}</span>
    <span v-if="notifications == '1'" class="icon__notification icon__notification--small"></span>
    <div :class="containerClass" v-if="background !== ''">
      <svg fill="currentColor" :class="svgClass" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
        <component :is="iconName" />
      </svg>
    </div>
    <svg fill="currentColor" :class="svgClass" v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
      <component :is="iconName" />
    </svg>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  icon!: string
  color = prop<string>({ default: '' })
  background = prop<string>({ default: '' })
  notifications = prop<number>({ default: 0 })
}

/**
 * @param {string} icon
 * @param {string=} color
 * @param {string=} background
 * @param {number=} notifications - amount to show
 */

@Options({
  name: 'Icon'
})

export default class Icon extends Vue.with(Props) {
  get iconName () {
    return this.icon
  }

  get containerClass () {
    const classes = {
      background: this.background !== '',
      icon: this.background !== ''
    }
    if (this.background !== '') { classes[`background--${this.background}`] = true }
    return classes
  }

  get svgClass () {
    const classes = {}
    classes[this.color] = true
    return classes
  }
}
</script>

<style lang="scss" scoped>
.icon_container {
  height: 26px;
  flex-shrink: 0;
  position: relative;
  width: 26px;
}

svg {
  height: 100%;
  width: 100%;
}

.background {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 4px;
  width: 26px;

  &--transparent {
    padding: 0;
  }
}

.icon__notification {
  @include brandon-grotesque-bold;
  background-color: $red;
  border-radius: 50%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  top: -13px;
  left: -13px;
  height: 27px;
  width: 27px;

  .is-active & {
    text-shadow: none;
  }

  &--small {
    top: -3px;
    left: -3px;
    height: 10px;
    width: 10px;
  }
}
</style>
