import axios from 'axios'

export const getNotifications = async (currentPage = 1, itemPerPage = 5) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/notifications',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: {
      currentPage: currentPage,
      itemPerPage: itemPerPage
    },
    data: {}
  })
}

export const patchNotification = async (id = '') => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `notifications/${id}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}
