import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Icons } from './assets/Icons'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import mixins from '@/mixins'
import { createI18n } from 'vue-i18n'
import messages from '../lang/messages.json'
import VueEasyLightbox from 'vue-easy-lightbox'
import { store, key } from './store'
import ServiceProvider from '@/services/serviceProvider'

/* eslint @typescript-eslint/no-var-requires: "off" */
const objectFitImages = require('object-fit-images')
objectFitImages()

const i18n = createI18n({
  locale: 'en',
  messages: messages,
  // force klh fallback for translation purposes
  fallbackLocale: 'klh'
})

const app = createApp(App)

const icons = new Icons()
icons.defineGlobalIcons(app)

app.use(ServiceProvider)
app.use(VueEasyLightbox)
app.mixin(mixins)
app.use(VueClipboard)
app.use(router)
app.use(i18n)
app.use(store, key)
app.mount('#app')
