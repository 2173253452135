<template>
  <div data-container :class="showModal ? 'modal_container is-active' : 'modal_container'" @click="closeModalOnBackgroundClick">
    <div class="modal">
      <IconButton class="modal__close-button" @click="closeModal" icon="x" background="transparent" round></IconButton>
      <div class="ta-center display-1">
        <slot name="title">#title</slot>
      </div>
      <Separator></Separator>
      <div class="modal__content" ref="modal-content">
        <slot>template</slot>
      </div>
      <div class="modal__footer" v-if="$slots['footer']">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import eventHub from '../event-hub'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

class Props {
  modalId!: string
}
@Options({
  name: 'Modal',
  components: {
    Button,
    IconButton,
    Separator
  }
})

/**
 * @param {string} modalId
 * @description {string} template#title
 * @description {HTML} template
 * @description {HTML} template#footer
 */
export default class Modal extends Vue.with(Props) {
  showModal = false

  closeModal () {
    this.showModal = false
    clearAllBodyScrollLocks()
  }

  openModal (id: string) {
    if (id === this.modalId) {
      this.showModal = true
      disableBodyScroll(this.$refs['modal-content'])
    }
  }

  closeModalOnBackgroundClick (ev: Event) {
    const target = ev.target as HTMLDivElement
    if (ev.target !== null && target.dataset.container === '') {
      this.closeModal()
    }
  }

  beforeUnmount () {
    this.showModal = false
  }

  mounted () {
    eventHub.$on('open-modal', (id: string) => {
      this.openModal(id)
    })

    eventHub.$on('close-modal', () => {
      this.closeModal()
    })
  }
}
</script>
<style lang="scss" scoped>
  .modal_container {
    @include z-index('modal');
    background-color: transparentize($blue-dark, 0.75);
    display: none;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.is-active {
      display: flex;
    }
  }

  .modal {
    background-color: white;
    border-radius: 10px;
    padding: 35px;
    max-height: 100%;
    position: relative;
    width: 700px;

    @include breakpoint(medium down) {
      display: flex;
      flex-direction: column;
      padding: $medium;
      padding-top: 35px;
    }
  }

  .modal__close-button {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .modal__content {
    @include breakpoint(xmedium down) {
      overflow-y: scroll;
    }
  }

  .modal__footer {
    margin-top: $medium;
  }
</style>
