import { reactive } from 'vue'
import { getCurrentFamilyMember, getFamilyMembers } from '@/api/family/members-api'
import { BankAccount } from '@/services/family/BankAccountService'

export interface Address {
  'number': string;
  'street': string;
  'city': string;
  'zipCode': string;
  'country': string;
}

export interface Member {
  'id': string;
  'type': string;
  'status': string;
  'statusNote': string;
  'firstName': string;
  'lastName': string;
  'matricule': string;
  'birthDate': string;
  'socialSecurityNumber': string;
  'healthInsurance': string;
  'healthMutual': string;
  'civilStatus': string;
  'totalRequestNumber': number;
  'email': string;
  'phone': string;
  'address': Address;
  'paymentFrequency': string;
  'bankAccounts': Array<BankAccount>;
}

export interface NewMember {
  firstName: string;
  lastName: string;
  relationship: string;
  gender: string;
  hasLuxemburgishSocialSecurityNumber: boolean;
  socialSecurityNumber: number;
  healthInsurance: string;
  healthMutual: string;
  healthMutualOther: string;
  email: string;
  certificate: string;
}

export interface BasicInformation {
  'healthInsurance'?: string;
  'civilStatus'?: string;
  'certificate'?: string;
  'partnerEmail'?: string;
}

export interface ContactInformation {
  'email'?: string;
  'phone'?: string;
  'address'?: Address;
  'residenceCertificate'?: string;
  'allFamilyEdit'?: boolean;
}

export interface DeleteInformation {
  'reason'?: string;
  'partnerEmail'?: string;
  'certificate'?: string;
  'reasonOther'?: string;
}

export class MemberService {
  members: Array<Member>;
  currentMember: Member;

  constructor () {
    this.members = reactive([])
    this.currentMember = {} as Member
  }

  loadMembers (forceReload = false) {
    if (this.members.length && !forceReload) {
      return Promise.resolve()
    }

    return getFamilyMembers()
      .then((response) => {
        this.members = response.data
        this.sortMembers()
      })
  }

  loadCurrentMember (forceReload = false) {
    if (this.currentMember.lastName && !forceReload) {
      return Promise.resolve()
    }

    return getCurrentFamilyMember()
      .then((response) => {
        this.currentMember = response.data
      })
  }

  sortMembers () {
    this.setMainMemberFirst()
    this.setPartnerSecond()
  }

  setMainMemberFirst () {
    const mainIndex = this.members.findIndex((member) => member.type === 'main')
    if (mainIndex !== -1) {
      this.swapPositions(this.members, 0, mainIndex)
    }
  }

  setPartnerSecond () {
    const partnerIndex = this.members.findIndex((member) => member.type === 'partner' || member.type === 'spouse')
    if (partnerIndex !== -1) {
      this.swapPositions(this.members, 1, partnerIndex)
    }
  }

  //taken from https://thewebdev.info/2021/05/23/how-to-reorder-arrays-with-javascript/
  swapPositions = (array: any, a: number, b: number) => {
    [array[a], array[b]] = [array[b], array[a]]
  }
}
