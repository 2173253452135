<template>
  <div v-show="loadingScreenState.display" class="loading-screen">
    <div class="loading-screen__container">
      <Logo class="loading-screen__logo" />
      <p class="display-1 ta-center">{{ $t('global.loading') }}</p>
      <LoadingDots />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { inject } from 'vue'
import { LoadingScreenKey } from '@/services/serviceTypes'
import Logo from '@/assets/logo.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Loading Screen',
  components: {
    LoadingDots,
    Logo
  }
})

export default class LoadingScreen extends Vue {
  loadingScreenState = inject(LoadingScreenKey)
}
</script>
<style lang="scss">
.loading-screen {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
}
</style>
