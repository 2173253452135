import axios from 'axios'
import { PatchInquiry } from '@/services/inquiries/InquiryService'

export const getInquiriesCategories = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/inquiries/categories',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getInquiries = async (status: string, currentPage = 1, itemPerPage = 5, memberId = 0) => {
  const bearer = window.localStorage.getItem('token')
  const params = {
    currentPage: currentPage,
    itemPerPage: itemPerPage
  }

  if (memberId !== 0) {
    params['memberId'] = memberId
  }

  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/inquiries/${status}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: params,
    data: {}
  })
}

export const getInquiry = async (id: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/inquiries/${id}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const deleteInquiry = async (id: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'delete',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/inquiries/${id}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const patchInquiry = async (id: string, inquiry: PatchInquiry) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/inquiries/${id}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: inquiry
  })
}

export const postInquiry = async (inquiry: PatchInquiry) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/inquiries',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: inquiry
  })
}
