import { reactive } from 'vue'
import { getSupportTopics } from '@/api/support/topics-api'

export interface Topic {
  id: string;
  topic: string;
}

export class TopicService {
  topics: Array<Topic>

  constructor () {
    this.topics = reactive([]) as Array<Topic>
  }

  loadTopics () {
    if (this.topics.length) {
      return Promise.resolve()
    }

    return getSupportTopics()
      .then((response) => {
        this.topics = response.data
      })
  }
}
