import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

export interface State {
  placeholder: string;
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    placeholder: 'string'
  },
  mutations: {

  }
})

export function useStore () {
  return baseUseStore(key)
}
