
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import eventHub from '../event-hub'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

class Props {
  modalId!: string
}
@Options({
  name: 'Modal',
  components: {
    Button,
    IconButton,
    Separator
  }
})

/**
 * @param {string} modalId
 * @description {string} template#title
 * @description {HTML} template
 * @description {HTML} template#footer
 */
export default class Modal extends Vue.with(Props) {
  showModal = false

  closeModal () {
    this.showModal = false
    clearAllBodyScrollLocks()
  }

  openModal (id: string) {
    if (id === this.modalId) {
      this.showModal = true
      disableBodyScroll(this.$refs['modal-content'])
    }
  }

  closeModalOnBackgroundClick (ev: Event) {
    const target = ev.target as HTMLDivElement
    if (ev.target !== null && target.dataset.container === '') {
      this.closeModal()
    }
  }

  beforeUnmount () {
    this.showModal = false
  }

  mounted () {
    eventHub.$on('open-modal', (id: string) => {
      this.openModal(id)
    })

    eventHub.$on('close-modal', () => {
      this.closeModal()
    })
  }
}
