const support = {
  path: '/support',
  component: {
    template: '<router-view />'
  },
  children: [
    {
      path: '',
      name: 'Support',
      component: () => import(/* webpackChunkName: "support" */ '../04-pages/06-Support/Support.vue')
    },
    {
      path: 'inbox',
      name: 'Inbox',
      component: () => import(/* webpackChunkName: "support" */ '../04-pages/06-Support/Support-inbox.vue'),
      children: [
        {
          path: ':id',
          name: 'Chat',
          component: () => import(/* webpackChunkName: "support" */ '../04-pages/06-Support/Support-inbox--chat.vue')
        }
      ]
    }
  ]
}

export default support
