import { getTripCancellationReasons } from '@/api/listings/trip-cancellation-reasons-api'

interface TripCancellationReason {
  id: string;
  name: string;
}

export class TripCancellationReasonService {
  tripCancellationReasons: Array<TripCancellationReason>

  constructor () {
    this.tripCancellationReasons = []
  }

  loadTripCancellationReasons () {
    if (this.tripCancellationReasons.length) {
      return Promise.resolve()
    }

    return getTripCancellationReasons()
      .then((response) => {
        this.tripCancellationReasons = response.data
      })
  }
}
