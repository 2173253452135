import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import inquiries from '@/router/02-inquiries'
import family from '@/router/03-family'
import documents from '@/router/04-documents'
import settings from '@/router/05-settings'
import support from '@/router/06-support'
import packages from '@/router/07-packages'
import { atoms, molecules, organisms } from '@/router/00-development'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Logged in',
    component: () => import(/* webpackChunkName: "loggedIn" */ '../05-templates/Logged-in.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        redirect: '/dashboard'
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../04-pages/01-General/Dashboard.vue')
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* webpackChunkName: "loggedIn" */ '../04-pages/01-General/Notifications.vue')
      },
      inquiries,
      family,
      documents,
      settings,
      support,
      packages
    ]
  },
  //development
  atoms,
  molecules,
  organisms,
  {
    path: '/login',
    name: 'Logged out',
    component: () => import(/* webpackChunkName: "loggedOut" */ '../05-templates/Logged-out.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "loggedOut" */ '../04-pages/01-General/Login.vue')
      },
      {
        path: '/reset-password',
        name: 'Reset password',
        component: () => import(/* webpackChunkName: "loggedOut" */ '../04-pages/01-General/Reset-password.vue')
      },
      {
        path: '/sign-up',
        name: 'Sign up',
        component: () => import(/* webpackChunkName: "loggedOut" */ '../04-pages/01-General/Sign-up.vue')
      },
      {
        path: '/expired-session',
        name: 'Expired Session',
        component: () => import(/* webpackChunkName: "loggedOut" */ '../04-pages/01-General/Expired-session.vue')
      },
      {
        path: '/qr/:type/:token',
        name: 'Qr File Upload',
        component: () => import(/* webpackChunkName: "loggedOut" */ '../04-pages/01-General/Qr-file-upload.vue')
      }
    ]
  }
]

/**
 * Enable back button with saved scrolling position
 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

/**
 * Check for token before loading a page
 * Redirect to login if no token is present
 */
router.beforeResolve(async (to) => {
  //taken from https://stackoverflow.com/questions/67480706/vue-router-beforeeach-caught-in-loop-and-cannot-load-login-template
  const token = window.localStorage.getItem('token')
  const requiresAuth = to.matched.some((route) => route.meta && route.meta.requiresAuth)

  const redirectToLogin = (route: any) => {
    if (route.name !== 'Login') {
      return { name: 'Login', replace: true, query: { redirectFrom: route.fullPath } }
    }
  }

  const redirectToDashboard = () => {
    return { name: 'Dashboard', replace: true }
  }

  if (!token && to.fullPath === '/') {
    return redirectToLogin(to)
  }

  if (!token && requiresAuth) {
    return redirectToLogin(to)
  }

  if (to.fullPath === '/') {
    return redirectToDashboard()
  }

  return true
})

export default router
