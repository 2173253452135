import { getHealthMutuals } from '@/api/listings/health-mutuals-api'

interface HealthMutual {
  id: string;
  name: string;
}

export class HealthMutualService {
  healthMutuals: Array<HealthMutual>

  constructor () {
    this.healthMutuals = []
  }

  loadHealthMutuals () {
    if (this.healthMutuals.length) {
      return Promise.resolve()
    }

    return getHealthMutuals()
      .then((response) => {
        this.healthMutuals = response.data
      })
  }
}
