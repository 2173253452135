import { reactive } from 'vue'
import { getBankAccounts } from '@/api/family/bank-accounts-api'

export interface BankAccount {
  'id': string;
  'name': string;
  'bic': string;
  'iban': string;
  'reimbursementAccount': boolean;
  'domiciliationAccount': boolean;
}

export interface PatchBankAccount {
  'memberId'?: string;
  'name'?: string;
  'bic'?: string;
  'iban'?: string;
  'bankAccountDetailsFile'?: string;
}

export class BankAccountService {
  bankAccounts: Array<BankAccount>;

  constructor () {
    this.bankAccounts = reactive([])
  }

  loadBankAccounts () {
    if (this.bankAccounts.length) {
      return Promise.resolve()
    }

    return getBankAccounts()
      .then((response) => {
        this.bankAccounts = response.data
      })
  }
}
