import { reactive } from 'vue'
import { getNotifications, patchNotification } from '@/api/notifications/notifications-api'

export interface Notification {
  'id': string;
  'createdAt': string;
  'title': string;
  'description': string;
  'isViewed': boolean;
  'icon': string;
  'type': string;
  'action': string;
  'actionEntryId': string;
}

export class NotificationService {
  notifications: Array<Notification>;
  notificationsPreview: Array<Notification>;

  constructor () {
    this.notifications = reactive([])
    this.notificationsPreview = reactive([])
  }

  loadNotificationsPreview (currentPage = 1, itemPerPage = 5, forceReload = false) {
    if (this.notificationsPreview.length >= itemPerPage && !forceReload) {
      return Promise.resolve()
    }

    return getNotifications(currentPage, itemPerPage)
      .then((response) => {
        this.notificationsPreview = response.data
      })
  }

  loadNotifications (currentPage = 1, itemPerPage = 5, forceReload = false) {
    if (this.notifications.length >= itemPerPage && !forceReload) {
      return Promise.resolve()
    }

    return getNotifications(currentPage, itemPerPage)
      .then((response) => {
        this.notifications = response.data
      })
  }

  loadOlderNotifications (nextPage = 2, itemPerPage = 5) {
    return getNotifications(nextPage, itemPerPage)
      .then((response) => {
        this.notifications.push(...response.data)
      })
  }

  updateNotification (id: string) {
    patchNotification(id)
      .then(() => {
        this.loadNotifications(1, 5, true)
        this.loadNotificationsPreview(1, 3)
      })

    this.notifications.forEach((notification) => {
      if (notification.id === id) {
        notification.isViewed = true
      }
    })

    this.notificationsPreview.forEach((notification) => {
      if (notification.id === id) {
        notification.isViewed = true
      }
    })
  }
}
