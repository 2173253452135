
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  icon!: string
  color = prop<string>({ default: '' })
  background = prop<string>({ default: '' })
  notifications = prop<number>({ default: 0 })
}

/**
 * @param {string} icon
 * @param {string=} color
 * @param {string=} background
 * @param {number=} notifications - amount to show
 */

@Options({
  name: 'Icon'
})

export default class Icon extends Vue.with(Props) {
  get iconName () {
    return this.icon
  }

  get containerClass () {
    const classes = {
      background: this.background !== '',
      icon: this.background !== ''
    }
    if (this.background !== '') { classes[`background--${this.background}`] = true }
    return classes
  }

  get svgClass () {
    const classes = {}
    classes[this.color] = true
    return classes
  }
}
