<template>
  <router-view/>
  <ErrorModal />
  <LoadingScreen />
  <StayConnectedModal />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ErrorModal from '@/03-organisms/Error-Modal.vue'
import LoadingScreen from '@/03-organisms/Loading-screen.vue'
import StayConnectedModal from '@/03-organisms/Stay-connected-modal.vue'
import mixins from '@/mixins'
import { logout } from '@/api/user/user-api'

@Options({
  name: 'App',
  components: {
    StayConnectedModal,
    LoadingScreen,
    ErrorModal
  }
})

export default class App extends Vue {
  beforeMount () {
    const htmlElement = window['document'].querySelector('html')!
    htmlElement.setAttribute('lang', this.$i18n.locale)

    this.checkToken()
    this.setTokenTimer()
  }

  setTokenTimer () {
    const minutes = 2
    const minutesInMilliSeconds = minutes * 60 * 1000
    setInterval(() => {
      this.checkToken()
    }, minutesInMilliSeconds)
  }

  checkToken () {
    const tokenTime = window.localStorage.getItem('tokenTime')
    if (tokenTime) {
      const dateNow = new Date()
      const dateCompareFormatted = new Date(JSON.parse(tokenTime))
      const milliSecondDifference = dateNow.getTime() - dateCompareFormatted.getTime()
      const minuteDifference = Math.floor(milliSecondDifference / 60000)

      if (minuteDifference > 25) {
        this.showRenewLoginMessage()
      }

      if (minuteDifference > 29) {
        logout()
      }
    }
  }

  showRenewLoginMessage () {
    mixins.methods.openModal('stayConnected')
  }
}
</script>
<style lang="scss">
@import 'scss/global';
</style>
