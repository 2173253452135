
import { Options, Vue } from 'vue-class-component'
import Modal from '@/02-molecules/Modal.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Error Modal',
  components: {
    Modal,
    Button
  }
})

export default class ErrorModal extends Vue {
  errorHandlerService = inject(ErrorHandlerKey)

  reload () {
    this.$router.go(-1)
    setTimeout(() => {
      this.$router.go(0)
    }, 500)
  }
}
