
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  background?: string
  icon?: string
  small?: string
  medium?: string
  lowercase?: string
  href?: string
  to?: string
  round?: string
  notifications?: number
}

@Options({
  name: 'Icon-button',
  components: {
    Icon
  }
})

/**
 *
 * Button with icon on the left side
 *
 * @param {string} icon
 * @param {string=} background - Default: blue-dark
 * @param {string=} small
 * @param {string=} medium
 * @param {string=} lowercase
 * @param {string=} href
 * @param {string=} to
 * @param {string=} round - Used without text
 * @param {number=} notifications
 *
 */
export default class IconButton extends Vue.with(Props) {
  get iconName () {
    return this.icon
  }

  get buttonClass () {
    const classes = {
      button: true,
      lowercase: this.lowercase === '',
      small: this.small === '',
      medium: this.medium === '',
      round: this.round === ''
    }
    classes[`background--${this.background}`] = true
    return classes
  }
}
