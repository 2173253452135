
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Loading Dots'
})

export default class LoadingDots extends Vue {

}
