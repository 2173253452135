<template>
  <Modal modalId="stayConnected">
    <template #title>{{ $t('stay-connected.title') }}</template>
    <div class="ta-center mb-xl">
      <p class="display-3">{{ $t('stay-connected.subtitle') }}</p>
      <p>{{ $t('stay-connected.message') }}</p>
    </div>
    <template #footer>
      <div class="ta-center">
        <LoadingDots v-if="isLoading" />
        <Button v-else @click="clickReconnect" class="mb-m">{{ $t('stay-connected.stay-connected') }}</Button>
        <br>
        <button @click="clickLogout" class="fs-14 fw-700 background--transparent grey-dark">{{ $t('global.logout') }}</button>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Modal from '@/02-molecules/Modal.vue'
import Button from '@/01-atoms/Button.vue'
import { logout, refresh, setToken } from '@/api/user/user-api'
import mixins from '@/mixins'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Error Modal',
  components: {
    LoadingDots,
    Modal,
    Button
  }
})

export default class StayConnectedModal extends Vue {
  isLoading = false

  errorHandlerService = inject(ErrorHandlerKey)

  clickReconnect () {
    this.isLoading = true
    const refreshToken = window.localStorage.getItem('refreshToken')
    refresh(refreshToken!)
      .then((response) => {
        setToken(response.data.token, response.data.refreshToken)
        mixins.methods.closeModal()
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  clickLogout () {
    logout()
    mixins.methods.closeModal()
  }
}
</script>
