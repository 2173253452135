const settings = {
  path: '/settings',
  component: {
    template: '<router-view />'
  },
  children: [
    {
      path: '',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings.vue')
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-setting.vue'),
      children: [
        {
          path: 'security-levels',
          name: 'Security levels',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-setting--security-levels.vue')
        },
        {
          path: 'login-security',
          name: 'Login and security',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-setting--login-security.vue')
        },
        {
          path: 'language-preferences',
          name: 'Language preferences',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-setting--language-preferences.vue')
        },
        {
          path: 'notification-settings',
          name: 'Notification settings',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-setting--notification-settings.vue')
        }
      ]
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-text.vue'),
      children: [
        {
          path: 'terms-and-conditions',
          name: 'Terms and conditions',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-text--terms-and-conditions.vue')
        },
        {
          path: 'privacy-policy',
          name: 'Privacy policy',
          component: () => import(/* webpackChunkName: "settings" */ '../04-pages/05-Settings/Settings-text--privacy-policy.vue')
        }
      ]
    }
  ]
}

export default settings
