import { getHealthInsurances } from '@/api/listings/healt-insurances-api'

interface HealthInsurance {
  id: string;
  name: string;
}

export class HealthInsuranceService {
  healthInsurances: Array<HealthInsurance>

  constructor () {
    this.healthInsurances = []
  }

  loadHealthInsurances () {
    if (this.healthInsurances.length) {
      return Promise.resolve()
    }

    return getHealthInsurances()
      .then((response) => {
        this.healthInsurances = response.data
      })
  }
}
