import { reactive } from 'vue'
import { getDashboardSlides } from '@/api/dashboard/slider-api'

export interface Slide {
  'title': string;
  'content': string;
  'linkText': string;
  'linkUrl': string;
  'backgroundImageUrl': string;
  'overlayColor': string;
}

export class SliderService {
  slides: Array<Slide>;

  constructor () {
    this.slides = reactive([])
  }

  loadSlides (forceReload = false) {
    if (this.slides.length && !forceReload) {
      return Promise.resolve()
    }

    return getDashboardSlides()
      .then((response) => {
        this.slides = response.data
      })
  }
}
