import { reactive } from 'vue'
import { Member } from '@/services/family/MemberService'
import { Topic } from '@/services/support/Topic-service'
import { getSupportTickets } from '@/api/support/tickets-api'

export interface Ticket {
  id: string;
  createdAt: string;
  lastMessage: Message;
  topic: Topic;
  concernedMember: Member;
}

export interface NewTicket {
  topicId: string;
  concernedMemberId: string;
  message: NewMessage;
  attachedFile?: string;
}

export interface NewMessage {
  content: string;
  attachedFile?: string;
}

export interface Message {
  id: string;
  createdAt?: string;
  content: string;
  isFromCmcm: boolean;
  attachedFile?: AttachmentPreview;
}

export interface AttachedFile {
  fileName: string;
  mimeType: string;
  content: string;
}

export interface AttachmentPreview {
  fileName: string;
  fileId: string;
  file: string;
}

export class TicketService {
  tickets: Array<Ticket>

  constructor () {
    this.tickets = reactive([]) as Array<Ticket>
  }

  addNewTicket () {
    getSupportTickets(1, 1)
      .then((response) => {
        this.tickets = [response.data[0], ...this.tickets]
      })
  }

  loadTickets (currentPage = 1, itemPerPage = 20, forceUpdate = false) {
    if (this.tickets.length && !forceUpdate) {
      return Promise.resolve()
    }

    return getSupportTickets(currentPage, itemPerPage)
      .then((response) => {
        this.tickets = response.data
      })
  }

  reloadNewestTicket () {
    getSupportTickets(1, 1)
      .then((response) => {
        this.filterOldTicker(response.data[0])
      })
  }

  filterOldTicker (ticket: Ticket) {
    this.tickets = this.tickets.filter((item) => {
      return item.id !== ticket.id
    })

    this.addNewestTicketToTop(ticket)
  }

  addNewestTicketToTop (ticket: Ticket) {
    this.tickets = [ticket, ...this.tickets]
  }

  loadOlderTickets (nextPage = 2, itemPerPage = 5) {
    return getSupportTickets(nextPage, itemPerPage)
      .then((response) => {
        this.tickets.push(...response.data)
      })
  }
}
