<template>
  <Modal modalId="error">
    <template #title>{{ $t('error.title') }}</template>
    <div class="ta-center mb-xl">
      <p class="display-3">{{ errorHandlerService.globalError.title }}</p>
      <p>{{ errorHandlerService.globalError.detail }}</p>
      <p v-if="errorHandlerService.globalError.showGlobalMessage">{{ $t('error.message') }}</p>
    </div>
    <template #footer>
      <div class="ta-center">
        <Button @click="reload" v-if="errorHandlerService.globalError.showGlobalMessage">{{ $t('global.reload') }}</Button>
        <Button @click="reload" v-else>{{ $t('global.close') }}</Button>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Modal from '@/02-molecules/Modal.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Error Modal',
  components: {
    Modal,
    Button
  }
})

export default class ErrorModal extends Vue {
  errorHandlerService = inject(ErrorHandlerKey)

  reload () {
    this.$router.go(-1)
    setTimeout(() => {
      this.$router.go(0)
    }, 500)
  }
}
</script>
