const inquiries = {
  path: '/inquiries',
  component: {
    template: '<router-view/>'
  },
  children: [
    {
      path: '',
      name: 'Inquiries',
      component: () => import(/* webpackChunkName: "inquiries" */ '../04-pages/02-Inquiries/Inquiries.vue')
    },
    {
      path: 'inquiry/:id',
      name: 'Inquiry',
      component: () => import(/* webpackChunkName: "inquiries" */ '../04-pages/02-Inquiries/Inquiries-inquiry.vue')
    },
    {
      path: 'new-inquiry',
      name: 'New inquiry',
      component: () => import(/* webpackChunkName: "inquiries" */ '../04-pages/02-Inquiries/Inquiries-new-inquiry.vue')
    },
    {
      path: 'inquiry/:id/edit',
      name: 'Edit inquiry',
      component: () => import(/* webpackChunkName: "inquiries" */ '../04-pages/02-Inquiries/Inquiries-edit-inquiry.vue')
    }
  ]
}

export default inquiries
