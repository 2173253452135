const packages = {
  path: '/packages',
  component: {
    template: '<router-view/>'
  },
  children: [
    {
      path: '',
      name: 'Packages',
      component: () => import(/* webpackChunkName: "packages" */ '../04-pages/07-Packages/Packages.vue')
    },
    {
      path: 'package',
      name: 'Package',
      component: () => import(/* webpackChunkName: "packages" */ '../04-pages/07-Packages/Packages-package.vue')
    }
  ]
}

export default packages
