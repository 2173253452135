<template>
  <div class="separator"></div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Separator'
})

export default class Separator extends Vue { }
</script>
<style lang="scss" scoped>
.separator {
  display: block;
  background: $blue-dark-10;
  height: 2px;
  margin: 36px 0;
  width: 100%;

  @include breakpoint(medium down) {
    margin: 15px 0;
  }
}
</style>
