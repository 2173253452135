import axios from 'axios'
import { NewMember, BasicInformation, ContactInformation, DeleteInformation } from '@/services/family/MemberService'

export const getFamilyMembers = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/family/members',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getFamilyMember = async (memberId: string | string[]) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${memberId}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getCurrentFamilyMember = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/family/members/current',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const postFamilyMember = async (member: NewMember) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/family/members',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: member
  })
}

export const patchFamilyMember = async (memberId: string | undefined, basicInformation: BasicInformation) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${memberId}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: basicInformation
  })
}

export const patchFamilyMemberContact = async (memberId: string | undefined, contactInformation: ContactInformation) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${memberId}/contact`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: contactInformation
  })
}

export const deleteFamilyMember = async (memberId: string | undefined, deleteInformation: DeleteInformation) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'delete',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${memberId}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: deleteInformation
  })
}

export const getFamilyMemberAccountPreferences = async (id: string | string[]) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${id}/account-preference`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export interface FamilyMemberAccountPreference {
  ownProfileEdit?: boolean;
  changeAddressAndBankAccount?: boolean;
  childrenInformationAccess?: boolean;
  allUploadAccess?: boolean;
  childrenUploadAccess?: boolean;
}

export const patchFamilyMemberAccountPreferences = async (id: string | string[], preferences: FamilyMemberAccountPreference) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/family/members/${id}/account-preference`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: preferences
  })
}
