import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export class LoadingScreenState {
  display: boolean

  constructor () {
    this.display = false
  }

  show () {
    this.display = true
    disableBodyScroll(window.document)
  }

  hide () {
    this.display = false
    enableBodyScroll(window.document)
  }
}
