import { reactive } from 'vue'
import { getSupportUploadDocumentList } from '@/api/support/upload-document-list-api'

export interface SupportDocument {
  'id': string;
  'type': 'document' | 'inquiry';
  'title': string;
  'submittedAt': string;
  'uploadedBy': string;
  'icon': string;
  'status': string;
}

export class UploadDocumentListService {
  uploadDocuments: Array<SupportDocument>

  constructor () {
    this.uploadDocuments = reactive([]) as Array<SupportDocument>
  }

  loadUploadDocuments (currentPage = 1, itemPerPage = 5) {
    if (this.uploadDocuments.length) {
      return Promise.resolve()
    }

    return getSupportUploadDocumentList(currentPage, itemPerPage)
      .then((response) => {
        this.uploadDocuments = response.data
      })
  }

  loadOlderDocuments (nextPage = 2, itemPerPage = 5) {
    return getSupportUploadDocumentList(nextPage, itemPerPage)
      .then((response) => {
        this.uploadDocuments.push(...response.data)
      })
  }
}
