import axios from 'axios'
import { PatchBankAccount } from '@/services/family/BankAccountService'

export const getBankAccounts = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/bank-accounts',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const patchBankAccount = async (id: string | undefined, bankInformation: PatchBankAccount) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/bank-accounts/${id}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: bankInformation
  })
}

export const postBankAccount = async (bankInformation: PatchBankAccount) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/bank-accounts',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: bankInformation
  })
}
