import { BankAccount } from '@/services/family/BankAccountService'
import { reactive } from 'vue'
import { getInquiries, getInquiriesCategories } from '@/api/inquiries/inquiries-api'

export interface Image {
  'fileName': string;
  'imageContent': string;
}

export interface PatchInquiry {
  'categoryId': string;
  'title': string;
  'bankAccount': string;
  'files': Array<string>;
}

export interface File {
  'id': string;
  'originalFileName': string;
  'uploadedAt': string;
  'pageNumber': number;
  'url': string;
  'fileType': string;
  'status': string;
  'action': string;
  'feedback': string;
}

export interface Category {
  'id': string;
  'title': string;
  'uploadType': string;
  'icon': string;
}

export interface Inquiry {
  'id': string;
  'uploadedAt': string;
  'deleteAt': string;
  'uploadedBy': string;
  'title': string;
  'cnsFolder': string;
  'files': Array<File>;
  'status': string;
  'treatmentStatus': string;
  'repliedAt': string;
  'uploadTotalRefund': string;
  'uploadExpiresAt': string;
  'category': Category;
  'bankAccount': BankAccount;
  'feedback': [];
  'splittedInquiries': [];
  'splitNote': string;
  'fileAnswer': [];
  'confirmation': string;
}

export class InquiryService {
  inquiries: {
    recent: [];
    reimbursed: [];
    rejected: [];
    pending: [];
  }

  categories: Array<Category>

  constructor () {
    this.inquiries = reactive({
      recent: [],
      reimbursed: [],
      rejected: [],
      pending: []
    })
    this.categories = reactive([] as Array<Category>)
  }

  loadCategories () {
    if (this.categories.length > 0) {
      return Promise.resolve()
    }

    return getInquiriesCategories()
      .then((response) => {
        this.categories = response.data
      })
  }

  loadInquiries (status = 'recent', currentPage = 1, itemPerPage = 5, forceUpdate = false, loadingFinishedCallback = (status: string) => { console.log('') }) {
    if (this.inquiries[status].length > 0 && !forceUpdate) {
      loadingFinishedCallback(status)
      return Promise.resolve()
    }

    return getInquiries(status, currentPage, itemPerPage)
      .then((response) => {
        this.inquiries[status] = response.data
        loadingFinishedCallback(status)
      })
  }

  loadOlderInquiries (status = 'recent', nextPage = 2, itemPerPage = 5, loadingFinishedCallback = (status: string) => { console.log('') }) {
    return getInquiries(status, nextPage, itemPerPage)
      .then((response) => {
        this.inquiries[status].push(...response.data)
        loadingFinishedCallback(status)
      })
  }
}
