<template>
  <router-link :class="buttonClass" v-if="to !== undefined" :to="to" :disabled="disabled === ''"><slot></slot></router-link>
  <a :class="buttonClass" v-else-if="href !== undefined" :href="href" :disabled="disabled === ''"><slot></slot></a>
  <button type="button" :class="buttonClass" :disabled="disabled === ''" v-else><slot></slot></button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
class Props {
  inverse?: string
  lowercase?: string
  href?: string
  to?: string
  background?: string
  isLoading?: boolean
  disabled?: string
}

/**
 * Default blue button
 *
 * @param {attribute=} inverse - inverse colors
 * @param {attribute=} lowercase
 * @param {string=} href
 * @param {string=} to
 * @param {string=} background
 * @param {boolean=} isLoading
 * @param {string=} disabled
 */

@Options({
  name: 'Button'
})

export default class Button extends Vue.with(Props) {
  get buttonClass () {
    const classes = {
      button: true,
      inverse: this.inverse === '',
      lowercase: this.lowercase === '',
      disabled: this.disabled === ''
    }
    if (this.background !== undefined) {
      classes[`background--${this.background}`] = true
    }

    if (this.isLoading) {
      classes['is-loading'] = true
    }
    return classes
  }
}
</script>

<style lang="scss" scoped>
  // Blue button white text

  .button {
    @include brandon-grotesque-bold;
    @include focus;
    appearance: none;
    background-color: $blue-dark;
    border: none;
    border-radius: 30px;
    color: $white;
    display: inline-block;
    font-size: 14px;
    padding: 8px 23px;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.5s ease;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.disabled:hover {
      cursor: not-allowed;
    }
  }

  .inverse {
    background: $white;
    color: $blue-dark;
  }

  .lowercase {
    text-transform: unset;
  }

  .is-loading {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
