import { InjectionKey } from 'vue'
import { SliderService } from '@/services/dashboard/SliderService'
import { NotificationService } from '@/services/notifications/NotificationService'
import { MemberService } from '@/services/family/MemberService'
import { PackageService } from '@/services/packages/PackageService'
import { ErrorHandler } from '@/services/ErrorHandler'
import { LoadingScreenState } from '@/services/LoadingScreenState'
import { HealthInsuranceService } from '@/services/listings/HealthInsuranceService'
import { HealthMutualService } from '@/services/listings/HealthMutualService'
import { InquiryService } from '@/services/inquiries/InquiryService'
import { BankAccountService } from '@/services/family/BankAccountService'
import { DocumentService } from '@/services/documents/DocumentService'
import { FaqService } from '@/services/support/Faq-service'
import { TicketService } from '@/services/support/Ticket-service'
import { TopicService } from '@/services/support/Topic-service'
import { UploadDocumentListService } from '@/services/support/Upload-document-list-service'
import { TripCancellationReasonService } from '@/services/listings/TripCancellationReasonService'

export const NotificationsServiceKey: InjectionKey<NotificationService> = Symbol('NotificationsService')
export const SliderServiceKey: InjectionKey<SliderService> = Symbol('SliderService')
export const MemberServiceKey: InjectionKey<MemberService> = Symbol('MemberService')
export const PackageServiceKey: InjectionKey<PackageService> = Symbol('PackageService')
export const ErrorHandlerKey: InjectionKey<ErrorHandler> = Symbol('ErrorHandlerService')
export const LoadingScreenKey: InjectionKey<LoadingScreenState> = Symbol('LoadingScreenState')
export const HealthInsuranceKey: InjectionKey<HealthInsuranceService> = Symbol('HealthInsuranceService')
export const HealthMutualKey: InjectionKey<HealthMutualService> = Symbol('HealthMutualService')
export const InquiryServiceKey: InjectionKey<InquiryService> = Symbol('InquiryService')
export const BankAccountServiceKey: InjectionKey<BankAccountService> = Symbol('BankAccountService')
export const DocumentServiceKey: InjectionKey<DocumentService> = Symbol('DocumentService')
export const FaqServiceKey: InjectionKey<FaqService> = Symbol('FaqService')
export const TicketServiceKey: InjectionKey<TicketService> = Symbol('TicketService')
export const TopicServiceKey: InjectionKey<TopicService> = Symbol('TopicService')
export const UploadDocumentListServiceKey: InjectionKey<UploadDocumentListService> = Symbol('UploadDocumentListService')
export const TripCancellationReasonServiceKey: InjectionKey<TripCancellationReasonService> = Symbol('TripCancellationReasonService')
