const family = {
  path: '/family',
  component: {
    template: '<router-view/>'
  },
  children: [
    {
      path: '',
      name: 'Family',
      component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family.vue')
    },
    {
      path: 'add-member',
      name: 'Add member',
      component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-add-member.vue')
    },
    {
      path: 'add-member-confirmation',
      name: 'Add member confirmation',
      component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-add-member-confirmation.vue')
    },
    {
      path: 'remove-member-confirmation',
      name: 'Remove member confirmation',
      component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member-remove-member-confirmation.vue')
    },
    {
      path: 'member/:id',
      name: 'Family member',
      component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member.vue'),
      children: [
        {
          path: 'personal-details',
          name: 'Personal details',
          component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member--personal-details.vue')
        },
        {
          path: 'remove-member',
          name: 'Remove member',
          component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member--remove-member.vue')
        },
        {
          path: 'account-preferences',
          name: 'Account preferences',
          component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member--account-preferences.vue')
        },
        {
          path: 'documents',
          name: 'Family member documents',
          component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member--documents.vue')
        },
        {
          path: 'inquiries',
          name: 'Family member inquiries',
          component: () => import(/* webpackChunkName: "family" */ '../04-pages/03-Family/Family-member--inquiries.vue')
        }
      ]
    }
  ]
}

export default family
