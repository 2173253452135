import axios from 'axios'
import { NewMessage, NewTicket } from '@/services/support/Ticket-service'

export const getSupportTickets = async (currentPage = 1, itemPerPage = 5) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/support/tickets',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: {
      currentPage: currentPage,
      itemPerPage: itemPerPage
    },
    data: {}
  })
}

export const getSupportTicket = async (id: string, messageCount = 10) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/support/tickets/${id}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: {
      messageCount: messageCount
    },
    data: {}
  })
}

export const getSupportTicketMessages = async (id: string, currentPage = 1, itemPerPage = 10) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/support/tickets/${id}/messages`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: {
      currentPage: currentPage,
      itemPerPage: itemPerPage
    },
    data: {}
  })
}

export const postSupportTicketMessage = async (id: string, message: NewMessage) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/support/tickets/${id}/messages`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: message
  })
}

export const postSupportTicket = async (ticket: NewTicket) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/support/tickets',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: ticket
  })
}
