import { App } from 'vue'
import {
  SliderServiceKey,
  NotificationsServiceKey,
  MemberServiceKey,
  PackageServiceKey,
  ErrorHandlerKey,
  LoadingScreenKey,
  HealthInsuranceKey,
  HealthMutualKey,
  InquiryServiceKey,
  BankAccountServiceKey,
  DocumentServiceKey, FaqServiceKey, TicketServiceKey, TopicServiceKey, UploadDocumentListServiceKey, TripCancellationReasonServiceKey
} from '@/services/serviceTypes'
import { SliderService } from '@/services/dashboard/SliderService'
import { NotificationService } from '@/services/notifications/NotificationService'
import { MemberService } from '@/services/family/MemberService'
import { PackageService } from '@/services/packages/PackageService'
import { ErrorHandler } from '@/services/ErrorHandler'
import { LoadingScreenState } from '@/services/LoadingScreenState'
import { HealthInsuranceService } from '@/services/listings/HealthInsuranceService'
import { HealthMutualService } from '@/services/listings/HealthMutualService'
import { InquiryService } from '@/services/inquiries/InquiryService'
import { BankAccountService } from '@/services/family/BankAccountService'
import { DocumentService } from '@/services/documents/DocumentService'
import { FaqService } from '@/services/support/Faq-service'
import { TicketService } from '@/services/support/Ticket-service'
import { TopicService } from '@/services/support/Topic-service'
import { UploadDocumentListService } from '@/services/support/Upload-document-list-service'
import { TripCancellationReasonService } from '@/services/listings/TripCancellationReasonService'

export default {
  install (app: App): void {
    app.provide(SliderServiceKey, new SliderService())
    app.provide(NotificationsServiceKey, new NotificationService())
    app.provide(MemberServiceKey, new MemberService())
    app.provide(PackageServiceKey, new PackageService())
    app.provide(ErrorHandlerKey, new ErrorHandler())
    app.provide(LoadingScreenKey, new LoadingScreenState())
    app.provide(HealthInsuranceKey, new HealthInsuranceService())
    app.provide(HealthMutualKey, new HealthMutualService())
    app.provide(InquiryServiceKey, new InquiryService())
    app.provide(BankAccountServiceKey, new BankAccountService())
    app.provide(DocumentServiceKey, new DocumentService())
    app.provide(FaqServiceKey, new FaqService())
    app.provide(TicketServiceKey, new TicketService())
    app.provide(TopicServiceKey, new TopicService())
    app.provide(UploadDocumentListServiceKey, new UploadDocumentListService())
    app.provide(TripCancellationReasonServiceKey, new TripCancellationReasonService())
  }
}
