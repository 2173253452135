<template>
  <div class="loading-dots_container">
    <div class="loading-dots" />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Loading Dots'
})

export default class LoadingDots extends Vue {

}
</script>
<style lang="scss" scoped>
.loading-dots_container {
  display: block;
  width: 100%;
}
.loading-dots {
  width: 50px;
  height: 24px;
  background:
    radial-gradient(circle closest-side,currentColor 90%,#0000) 0%   50%,
    radial-gradient(circle closest-side,currentColor 90%,#0000) 50%  50%,
    radial-gradient(circle closest-side,currentColor 90%,#0000) 100% 50%;
  background-size:calc(100%/3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
  margin: 0 auto;
}
@keyframes d3 {
  20%{background-position:0%   0%, 50%  50%,100%  50%}
  40%{background-position:0% 100%, 50%   0%,100%  50%}
  60%{background-position:0%  50%, 50% 100%,100%   0%}
  80%{background-position:0%  50%, 50%  50%,100% 100%}
}
</style>
