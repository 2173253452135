import axios from 'axios'

export const getPackages = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/packages',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getCurrentPackage = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/packages/current',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getRecommendedPackage = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/packages/recommended',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getPackageRequest = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/packages/change/request',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const postPackageRequest = async (id: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/packages/change/request',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {
      packageId: id
    }
  })
}
