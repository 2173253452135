const documents = {
  path: '/documents',
  component: {
    template: '<router-view/>'
  },
  children: [
    {
      path: '',
      name: 'Documents',
      component: () => import(/* webpackChunkName: "documents" */ '../04-pages/04-Documents/Documents.vue')
    },
    {
      path: 'document/:id',
      name: 'Document',
      component: () => import(/* webpackChunkName: "documents" */ '../04-pages/04-Documents/Documents-document.vue')
    },
    {
      path: 'document/:id/payment',
      name: 'Document payment',
      component: () => import(/* webpackChunkName: "documents" */ '../04-pages/04-Documents/Documents-document-payment.vue')
    },
    {
      path: 'document/:id/upload',
      name: 'Document upload',
      component: () => import(/* webpackChunkName: "documents" */ '../04-pages/04-Documents/Documents-document-upload.vue')
    },
    {
      path: 'document-upload-confirmation',
      name: 'Document upload confirmation',
      component: () => import(/* webpackChunkName: "documents" */ '../04-pages/04-Documents/Documents-document-upload-confirmation.vue')
    }
  ]
}

export default documents
