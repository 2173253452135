import { BankAccount } from '@/services/family/BankAccountService'
import { Member } from '@/services/family/MemberService'
import { reactive } from 'vue'
import { getDocuments } from '@/api/documents/documents-api'

export interface DocumentBase {
  'title': string;
  'submittedAt': string;
  'id': string;
  'files': Array<DocumentFile>;
}

export interface Contract extends DocumentBase {
  'startsAt': string;
  'endsAt': string;
  'paymentFrequency': 'yearly' | 'monthly';
  'documentType': 'contract';
}

export interface DocumentFile {
  'id': string;
  'originalFileName': string;
  'uploadedAt': string;
  'pageNumber': number;
  'url': string;
  'fileType': string;
  'status': string;
}

export interface Invoice extends DocumentBase {
  'status': 'pending' | 'paid';
  'paymentDueDate': string;
  'membershipStartDate': string;
  'membershipEndDate': string;
  'paymentMethod': 'transfer' | 'digicash' | 'bank_domiciliation' | 'paid_by_employer';
  'bankAccount': BankAccount;
  'amount': number;
  'frequency': 'yearly' | 'bi_yearly' | 'monthly';
  'documentType': 'invoice';
}

export interface Certificate extends DocumentBase {
  'uploadedBy': Member;
  'affectedMember': Member;
  'status': 'pending' | 'to_be_renewed' | 'valid' | 'accepted';
  'repliedAt': string;
  'expiresAt': string;
  'documentType': 'certificate';
}

export interface Domiciliation extends DocumentBase {
  'status': 'pending' | 'to_be_renewed' | 'valid' | 'accepted';
  'startsAt': string;
  'bankAccount': BankAccount;
  'amount': number;
  'frequency': 'yearly' | 'bi_yearly' | 'monthly';
  'signedFileUrl': string;
  'documentType': 'domiciliation';
}

export interface Document extends DocumentBase {
  'description': string;
  'documentType': 'document';
}

export class DocumentService {
  documents: {
    invoices: [];
    certificates: [];
    other: [];
  }

  constructor () {
    this.documents = reactive({
      invoices: [],
      certificates: [],
      other: []
    })
  }

  loadDocuments (type: string, currentPage = 1, itemPerPage = 5, forceUpdate = false) {
    if (this.documents[type].length > 0 && !forceUpdate) {
      return Promise.resolve()
    }

    return getDocuments(type, currentPage, itemPerPage)
      .then((response) => {
        this.documents[type] = response.data
      })
  }

  loadOlderDocuments (type: string, nextPage = 2, itemPerPage = 5) {
    return getDocuments(type, nextPage, itemPerPage)
      .then((response) => {
        this.documents[type].push(...response.data)
      })
  }
}
