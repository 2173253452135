
import { Options, Vue } from 'vue-class-component'
class Props {
  inverse?: string
  lowercase?: string
  href?: string
  to?: string
  background?: string
  isLoading?: boolean
  disabled?: string
}

/**
 * Default blue button
 *
 * @param {attribute=} inverse - inverse colors
 * @param {attribute=} lowercase
 * @param {string=} href
 * @param {string=} to
 * @param {string=} background
 * @param {boolean=} isLoading
 * @param {string=} disabled
 */

@Options({
  name: 'Button'
})

export default class Button extends Vue.with(Props) {
  get buttonClass () {
    const classes = {
      button: true,
      inverse: this.inverse === '',
      lowercase: this.lowercase === '',
      disabled: this.disabled === ''
    }
    if (this.background !== undefined) {
      classes[`background--${this.background}`] = true
    }

    if (this.isLoading) {
      classes['is-loading'] = true
    }
    return classes
  }
}
