export const atoms = {
  path: '/design-system',
  component: () => import(/* webpackChunkName: "development" */ '../00-development/Atoms.vue')
}
export const molecules = {
  path: '/molecules',
  component: () => import(/* webpackChunkName: "development" */ '../00-development/Molecules.vue')
}
export const organisms = {
  path: '/organisms',
  component: () => import(/* webpackChunkName: "development" */ '../00-development/Organisms.vue')
}
