
import { Options, Vue } from 'vue-class-component'
import { inject } from 'vue'
import { LoadingScreenKey } from '@/services/serviceTypes'
import Logo from '@/assets/logo.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Loading Screen',
  components: {
    LoadingDots,
    Logo
  }
})

export default class LoadingScreen extends Vue {
  loadingScreenState = inject(LoadingScreenKey)
}
