import axios from 'axios'
import router from '@/router'
import mixins from '@/mixins'

/**
 *
 * @param username
 * @param password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = async (username: string, password: string) => {
  return axios(
    {
      method: 'post',
      baseURL: process.env.VUE_APP_API_URL,
      url: '/user/login',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        username: username,
        password: password
      }
    }
  )
}

export const refresh = async (refreshToken: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios(
    {
      method: 'post',
      baseURL: process.env.VUE_APP_API_URL,
      url: '/user/login/refresh',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      },
      data: {
        refreshToken: refreshToken
      }
    }
  )
}

export interface NewUser {
  'firstname': string;
  'lastname': string;
  'email': string;
  'password': string;
}

export const signup = async (newUser: NewUser) => {
  return axios(
    {
      method: 'post',
      baseURL: process.env.VUE_APP_API_URL,
      url: '/user/signup',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: newUser
    }
  )
}

export const forgottenPassword = async (email: string) => {
  return axios(
    {
      method: 'post',
      baseURL: process.env.VUE_APP_API_URL,
      url: '/user/forgotten-password',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        email: email
      }
    }
  )
}

export const resetPassword = async (token: any, password: string) => {
  return axios(
    {
      method: 'post',
      baseURL: process.env.VUE_APP_API_URL,
      url: `/user/reset-password/${token}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        password: password
      }
    }
  )
}

export const setTimeToken = () => {
  const date = JSON.stringify(new Date())
  window.localStorage.setItem('tokenTime', date)
}

export const setToken = (token: string, refreshToken: string) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('refreshToken', refreshToken)

  setTimeToken()
}

export const removeToken = () => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('refreshToken')
  window.localStorage.removeItem('tokenTime')
}

export const logout = () => {
  removeToken()
  router.push({ name: 'Login' })
  mixins.methods.closeModal()
}
