import axios from 'axios'

export const getDocuments = async (documentType: string, currentPage = 1, itemPerPage = 5, searchQuery = '') => {
  const params = new URLSearchParams()
  params.append('currentPage', currentPage.toString())
  params.append('itemPerPage', itemPerPage.toString())
  params.append('searchQuery', searchQuery)

  if (documentType === 'other') {
    params.append('documentType[]', 'document')
    params.append('documentType[]', 'contract')
    params.append('documentType[]', 'domiciliation')
  } else {
    const type = documentType.substring(0, documentType.length - 1) // remove plural 's'
    params.append('documentType[]', type)
  }

  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/documents',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: params,
    data: {}
  })
}

export const getDocument = async (id: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/documents/${id}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const patchDocument = async (id: string, files: Array<string>) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'patch',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/documents/${id}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {
      files: files
    }
  })
}

export interface PostTripCancellationForm {
  'memberId': string;
  'cancellationReason': string;
}

export const postTripCancellationForm = async (tripCancellation: PostTripCancellationForm) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/documents/trip-cancellation-form',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: tripCancellation
  })
}
