import { reactive } from 'vue'
import { getSupportFaq } from '@/api/support/faq-api'

interface QA {
  question: string;
  answer: string;
}

export class FaqService {
  faqList: Array<QA>

  constructor () {
    this.faqList = reactive([]) as Array<QA>
  }

  loadFaq () {
    if (this.faqList.length) {
      return Promise.resolve()
    }

    return getSupportFaq()
      .then((response) => {
        this.faqList = response.data
      })
  }
}
